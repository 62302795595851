<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    :default-submit-buttons="false"
    @validated="playingSurface ? update(): create()"
  >
    <modal-card
      :card="playingSurface === null"
      :title="playingSurface ? `Edit ${playingSurfaceType}` : `Add ${playingSurfaceType}`"
      @show="Object.assign(form, playingSurface)"
    >
      <template #default="{ isCard }">
        <b-row>
          <!-- Name -->
          <b-col sm="auto">
            <form-validation-field
              ref="name"
              v-model="form.name"
              name="name"
              rules="required"
              label="Name*"
              placeholder="Name"
              aria-autocomplete="none"
              autofocus
            />
          </b-col>

          <portal
            to="modal-footer-add-playing-surface"
            :disabled="isCard && !playingSurface"
          >
            <submit-buttons
              :class="{ 'mt-sm-2': isCard }"
              :loading="form.isLoading"
              :invalid="observer.failed"
              @save="$refs.validationForm.validate()"
              v-on="$listeners"
            />
          </portal>
        </b-row>
      </template>

      <template #modal-footer>
        <portal-target name="modal-footer-add-playing-surface" />
      </template>
    </modal-card>
  </validation-form>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import ModalCard from '@/components/modals/ModalCard.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    ModalCard,
    ValidationForm,
    FormValidationField,
    SubmitButtons,
  },
  props: {
    playingSurface: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: new Form({
        name: '',
        sport: this.$route.query.sport,
      }),
    }
  },
  computed: mapState('app/sport', ['playingSurfaceType']),
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    create() {
      this.form.post(`/venues/${this.$route.params.venue}/playing-surfaces`, true)
        .then(response => {
          this.$emit('add-playing-surface', response.data)
          document.getElementById('name').focus()
        })
    },
    update() {
      this.form.put(`/playing-surfaces/${this.playingSurface.id}`)
        .then(() => {
          this.$emit('update-playing-surface', this.form)
        })
    },
  },
}
</script>

<style scoped>

</style>
