<template>
  <section>
    <organization-venue-playing-surface
      v-if="showPlayingSurface"
      :playing-surface="playingSurface"
      @add-playing-surface="handleAddPlayingSurface"
      @update-playing-surface="handleUpdatePlayingSurface"
      @cancel="handleCancel"
    />

    <b-card no-body>
      <card-header-filters
        :button="{ show: !showPlayingSurface || playingSurface }"
        @add="showPlayingSurface = true"
      >
        <template #title>
          <span
            class="d-block text-black-50"
            style="font-size: 1rem"
          >
            {{ $attrs.information.name }}
          </span>
          {{ playingSurfaceType }}
        </template>
      </card-header-filters>

      <!-- Playing Surfaces List -->
      <table-list
        primary-key="id"
        :item-name="playingSurfaceType"
        :fields="fields"
        :items="playingSurfaces"
        :endpoints="{ delete: '/playing-surfaces' }"
        :delete-modal="{
          text: `Deleting this court will affect all leagues using this court. Are you sure you want to permanently delete this?`
        }"
        @link-clicked="handleShowEditPlayingSurface"
      />
    </b-card>
  </section>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import OrganizationVenuePlayingSurface from '@/views/organizations/venues/playing-surfaces/OrganizationVenuePlayingSurface.vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import TableList from '@/components/TableList.vue'

import axios from '@/libs/axios'
import { mapState } from 'vuex'
import useFlash from '@/composables/useFlash'
import store from '@/store'

const { flashError } = useFlash()

export default {
  name: 'OrganizationVenuePlayingSurfaces',
  components: {
    BCard,
    OrganizationVenuePlayingSurface,
    CardHeaderFilters,
    TableList,
  },
  data() {
    return {
      showPlayingSurface: false,
      playingSurface: null,
      playingSurfaces: [],
      fields: [
        'name',
      ],
    }
  },
  computed: mapState('app/sport', ['playingSurfaceType']),
  beforeRouteEnter(to, from, next) {
    axios.get(`/venues/${to.params.venue}/playing-surfaces?sport=${store.state.app.sport.name}`)
      .then(response => {
        const { data } = response.data

        next(vm => {
          vm.playingSurfaces = data
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  methods: {
    handleShowEditPlayingSurface(surface) {
      this.showPlayingSurface = true
      this.playingSurface = surface
    },
    handleCancel() {
      this.showPlayingSurface = false
      this.playingSurface = null
    },
    handleAddPlayingSurface(surface) {
      this.playingSurfaces.push(surface)
      this.sortPlayingSurfaces()
    },
    handleUpdatePlayingSurface(surface) {
      this.playingSurfaces.splice(
        this.playingSurfaces.findIndex(ps => ps.id === surface.id),
        1,
        surface,
      )

      this.sortPlayingSurfaces()
      this.showPlayingSurface = false
    },
    sortPlayingSurfaces() {
      // Resort playing surfaces alphabetically.
      this.playingSurfaces.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()

        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        // names must be equal
        return 0
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
